<template>
  <v-card elevation="2" class="mx-4 mt-4 d-flex justify-center">
    <v-card-text>
      <v-row>
        <v-col>
          <label class="black--text"> Administração </label>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-list>
        <div v-for="item in items" :key="item.title">
          <v-list-group
            :prepend-icon="item.icon"
            :value="true"
            v-if="item.subitems"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="subitem in item.subitems"
              :key="subitem.title"
              :to="{ path: subitem.route }"
              exact
              link
            >
              <v-list-item-icon v-if="subitem.icon != ''">
                <v-icon color="#00000079">{{ subitem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :to="{ path: item.route }" exact link>
            <v-list-item-icon v-if="item.icon != ''">
              <v-icon color="#00000079">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Usuários",
        icon: "mdi-account-group",
        subitems: [
          {
            title: "Gerenciar Usuários",
            icon: "",
            route: "/admin/usuarios",
          },
          {
            title: "Grupos",
            icon: "",
            route: "/admin/usuarios/grupos",
          },
        ],
      },
      {
        title: "Poderes",
        icon: "mdi-bank",
        route: "/admin/poderes",
      },
      {
        title: "Dialéticos",
        icon: "mdi-seal",
        route: "/admin/instituicoes/dialeticos",
      },
      {
        title: "Instituições (Escolas)",
        icon: "mdi-school",
        route: "/admin/instituicoes",
      },
      {
        title: "Falácias",
        icon: "mdi-comment-alert",
        route: "/admin/falacias",
      },
      {
        title: "Política de Privacidade",
        icon: "mdi-note-text",
        route: "/admin/termos/editar",
      },
    ],
  }),
};
</script>

<style scoped>
.v-list-item--link:before {
  background-color: #fff;
}

.theme--light.v-icon {
    color: #00000079;
}

</style>
