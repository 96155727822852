import VueRouter from "vue-router";
import Vue from "vue";

import users from "./users";
import institutions from "./institutions";
import fallacies from "./fallacies";
import rooms from "./rooms";
import games from "./games";
import userGroups from "./userGroups";
import stateEntities from "./stateEntities";
import dialeticsInstitution from "./dialeticsInstitution";

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "Home" */ "../components/HelloWorld.vue"),
    },
    {
      path: "/login",
      name: "Login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    {
      path: "/recuperarsenha",
      name: "RecoveryPassword",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "Login" */ "../views/Users/RecoveryPassword.vue"
        ),
    },
    {
      path: "/feed",
      name: "gameFeed",
      component: () =>
        import(/* webpackChunkName: "GetRoomsToAdmin" */ "../views/Feed.vue"),
    },
    {
      path: "/admin/termos/editar",
      name: "UpdateTerms",
      props: {
        mode: "update",
      },
      component: () =>
        import(
          /* webpackChunkName: "UpdateTerms" */ "../views/Terms/UpdateTermsForm.vue"
        ),
    },
    ...users,
    ...userGroups,
    ...institutions,
    ...fallacies,
    ...rooms,
    ...games,
    ...stateEntities,
    ...dialeticsInstitution,
  ],
});

export default router;
