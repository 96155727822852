export default [
  {
    path: "/admin/instituicoes",
    name: "AdminInstitutions",
    component: () =>
      import(
        /* webpackChunkName: "AdminInstitutions" */ "../views/Institutions/Institutions.vue"
      ),
  },
  {
    path: "/admin/instituicoes/cadastrar",
    name: "RegisterInstitutions",
    props: {
      title: "Cadastrar instituição",
      mode: "insert",
      breadcrumbs: [
        {
          text: "Administração de instituições",
          href: "/#/admin/instituicoes",
          disabled: false,
        },
        {
          text: "Registrar instituição",
          href: "/#/admin/instituicoes/cadastrar",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterInstitutions" */ "../views/Institutions/InstitutionsForm.vue"
      ),
  },
  {
    path: "/admin/instituicoes/editar/:id",
    name: "UpdateInstitutions",
    props: {
      title: "Editar instituição",
      mode: "update",
      breadcrumbs: [
        {
          text: "Administração de instituições",
          href: "/#/admin/instituicoes",
          disabled: false,
        },
        {
          text: "Editar instituição",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateInstitutions" */ "../views/Institutions/InstitutionsForm.vue"
      ),
  },
  {
    path: "/admin/instituicao/:id/usuarios",
    name: "InstitutionLinkedUsers",
    props: {
      breadcrumbs: [
        {
          text: "Administração de instituições",
          href: "/#/admin/instituicoes",
          disabled: false,
        },
        {
          text: "Usuários vinculados",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "InstitutionLinkedUsers" */ "../views/Institutions/LinkedUsers.vue"
      ),
  },
  {
    path: "/admin/instituicao/:id/usuarios/vincular",
    name: "LinkNewUserToInstitution",
    component: () =>
      import(
        /* webpackChunkName: "LinkNewUserToInstitution" */ "../views/Institutions/LinkNewUsers.vue"
      ),
  },
  {
    path: "/admin/solicitacoes/buscar/:id",
    name: "TeacherPenndingRequests",
    props: {
      breadcrumbs: [
        {
          text: "Administração de instituições",
          href: "/#/admin/instituicoes",
          disabled: false,
        },
        {
          text: "Solicitações pendentes",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "TeacherPenndingRequests" */ "../views/Institutions/TeacherPenndingRequests.vue"
      ),
  },
];
