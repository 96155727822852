import axios from "axios";
import Vue from "vue";
import axiosInterceptors from "../config/axios/interceptors";
import { baseUrl } from "../config/config.json";

function createInstance(baseURL) {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  axiosInterceptors(instance);
  
  return instance;
}

export default {
  install() {
    Vue.prototype.$axios = createInstance(baseUrl);
  },
};
