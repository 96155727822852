export default [
  {
    path: "/admin/instituicoes/dialeticos",
    name: "AdminDialeticInstitution",
    component: () =>
      import(
        /* webpackChunkName: "AdminDialeticInstitution" */ "../views/DialeticsInstitution/DialeticsInstitution.vue"
      ),
  },
];
