export default [
  {
    path: "/admin/usuarios/grupos",
    name: "UserGroups",
    component: () =>
      import(
        /* webpackChunkName: "UserGroups" */ "../views/UserGroups/UserGroups.vue"
      ),
  },
  {
    path: "/admin/usuarios/grupos/:id/editar",
    name: "UpdateUserGroupsAdmin",
    props: {
      mode: "update",
      breadcrumbs: [
        {
          text: "Administração de grupos usuários",
          href: "/#/admin/usuarios/grupos",
          disabled: false,
        },
        {
          text: "Editar grupo",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateUserGroupsAdmin" */ "../views/UserGroups/UserGroupsForm.vue"
      ),
  },
];
