<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title>Atenção</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list flat>
          <v-list-item v-for="m in messages" :key="m">
            <v-list-item-icon>
              <v-icon color="error">mdi-close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ m }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.stop="dialog = false"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  data: () => ({
    dialog: false,
    messages: null,
    options: {
      color: "primary",
      width: 500,
      zIndex: 200,
    },
  }),
  methods: {
    open(messages, options) {
      this.messages = messages;
      this.options = Object.assign(this.options, options);
      this.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
</style>