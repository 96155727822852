export default [
  {
    path: "/admin/falacias",
    name: "GetFallaciesToAdmin",
    component: () =>
      import(
        /* webpackChunkName: "GetFallaciesToAdmin" */ "../views/Fallacies/Fallacies.vue"
      ),
  },
  {
    path: "/admin/falacias/cadastrar",
    name: "RegisterNewFallacy",
    props: {
      title: "Nova Falácia",
      mode: "insert",
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterNewFallacy" */ "../views/Fallacies/FallaciesForm.vue"
      ),
  },
  {
    path: "/admin/falacias/:fallacyId/editar",
    name: "UpdateFallacy",
    props: {
      title: "Editar falácia",
      mode: "update",
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateFallacy" */ "../views/Fallacies/FallaciesForm.vue"
      ),
  },
];
