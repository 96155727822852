const state = {
  moderators: [],
};

const mutations = {
  setModerators(state, data) {
    state.moderators = [...data].map((m) => {
      return { ...m, fullName: `${m.firstName} ${m.lastName}` };
    });
  },
  clearModerators(state) {
    state.moderators = [];
  },
  deleteModerator(state, id) {
    const index = state.moderators.findIndex((m) => m.id === id);

    state.moderators.splice(index, 1);
  },
};

const actions = {
  async getModerators({ commit }, gameId) {
    const response = await this._vm.$axios.get(
      `/admin/jogo/${gameId}/moderadores`
    );

    const data = { ...response.data };

    //Salvando no store (memória)
    commit("setModerators", data.GameModerators);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
