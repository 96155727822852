import Moment from "moment-timezone";
import Vue from "vue";

Moment.tz.setDefault("America/Sao_Paulo");

import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

moment.locale("pt-BR")

export default {
  install() {
    Vue.prototype.$moment = moment;
  },
};
