import store from "../../store";

function unauthorizedUser(instance) {
  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.response.status === 401) {
        store.commit("user/clearUser");
        window.location.href = "/login";
      } else {
        return Promise.reject(error);
      }
    }
  );
}

//function requestInterceptors() {}

function responseInterceptors(instance) {
  unauthorizedUser(instance);
}

export default function setup(instance) {
  //requestInterceptors(instance);
  responseInterceptors(instance);
}
