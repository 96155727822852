<template>
  <div class="left-bar d-flex justify-center flex-column">
    <v-card
      :loading="loading"
      elevation="2"
      class="mx-4 mt-4 d-flex justify-center"
    >
      <v-navigation-drawer floating permanent>
        <template v-slot:prepend>
          <v-list-item two-line class="d-flex justify-center mt-4">
            <v-img
              max-width="100"
              class="profile-image"
              :src="srcProfileImage"
            ></v-img>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-center">
                <span class="user-name">{{ userName }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-center">
                {{ userEmail }}</v-list-item-subtitle
              >
              <p class="d-flex justify-center mt-2">
                {{ userGroup }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider class="mx-3"></v-divider>

        <!-- Menu -->
        <InstitutionsAndRoomsTeacher
          v-if="userData && userData.Group.group_code == 2"
        />

        <InstitutionsAndRoomsStudents
          v-if="userData && userData.Group.group_code == 1"
        />
      </v-navigation-drawer>
    </v-card>
    <MenuAdmin v-if="userData && userData.Group.group_code == 4" />
  </div>
</template>

<script>
import profileImage from "@/assets/profile.png";
import errorHandler from "@/helpers/error_handler";
import { baseUrl } from "@/config/config.json";
import { mapState, mapActions } from "vuex";
import MenuAdmin from "./MenuAdmin.vue";
import InstitutionsAndRoomsTeacher from "./InstitutionsAndRoomsTeacher.vue";
import InstitutionsAndRoomsStudents from "./InstitutionsAndRoomsStudents.vue";

export default {
  components: {
    MenuAdmin,
    InstitutionsAndRoomsTeacher,
    InstitutionsAndRoomsStudents,
  },
  computed: {
    ...mapState("user", ["userData", "profilePhoto"]),
    userName() {
      if (!this.userData) return null;

      return `${this.userData.firstName} ${this.userData.lastName}`;
    },
    userEmail() {
      if (!this.userData) return null;

      return `${this.userData.email}`;
    },
    userGroup() {
      if (!this.userData) return null;

      return `${this.userData.Group.group_name}`;
    },
    srcProfileImage() {
      if (this.profilePhoto)
        return `${baseUrl}/uploads/usersavatar/${this.profilePhoto}`;

      return profileImage;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("user", ["loadProfilePhoto"]),
    async getProfilePhoto() {
      try {
        this.loading = true;

        if (!this.profilePhoto) await this.loadProfilePhoto();
      } catch (error) {
        console.log(error);

        const errorHandled = errorHandler.treatError(error);

        errorHandled.map((e) => this.$toast.error(e));
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getProfilePhoto();
  },
};
</script>

<style scoped>
.profile-image {
  border-radius: 50%;
}

.user-name {
  font-size: x-large;
}

.v-navigation-drawer {
  width: 100% !important;
}
</style>
