import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import moderator from "./modules/moderator";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { user, moderator },
  strict: true,
});

export default store;
