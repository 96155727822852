export default [
  {
    path: "/salas/:roomId/poder/:entityId/jogos",
    name: "GamesOfTheRoom",
    component: () =>
      import(
        /* webpackChunkName: "GamesOfTheRoom" */ "../views/Games/Games.vue"
      ),
  },
  {
    path: "/dialeticos/poder/:entityId/jogos",
    name: "GamesOfTheDialeticsInstitution",
    props: {
      fromDialeticInstitution: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "GamesOfTheDialeticsInstitution" */ "../views/Games/Games.vue"
      ),
  },
  {
    path: "/salas/:roomId/jogos/cadastrar/:entityId?",
    name: "RegisterNewGame",
    props: {
      title: "Novo debate",
      mode: "insert",
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterNewGame" */ "../views/Games/GamesForm.vue"
      ),
  },
  {
    path: "/jogos/dialeticos/cadastrar",
    name: "RegisterNewGameFromDialeticInstitution",
    props: {
      title: "Novo debate da Instituição Dialeticos",
      mode: "insert",
      fromDialeticInstitution: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterNewGameFromDialeticInstitution" */ "../views/Games/GamesForm.vue"
      ),
  },
  {
    path: "/jogos/:gameId/dialeticos/editar",
    name: "UpdateGameFromDialeticInstitution",
    props: {
      title: "Editar debate da Instituição Dialeticos",
      mode: "update",
      fromDialeticInstitution: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateGameFromDialeticInstitution" */ "../views/Games/GamesForm.vue"
      ),
  },
  {
    path: "/salas/:roomId/jogos/:gameId/editar",
    name: "UpdateGame",
    props: {
      title: "Editar debate",
      mode: "update",
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateGame" */ "../views/Games/GamesForm.vue"
      ),
  },
  {
    path: "/sala/:roomId/jogo/:gameId",
    name: "GameScreen",
    component: () =>
      import(/* webpackChunkName: "GameScreen" */ "../views/Games/Game.vue"),
  },
  {
    path: "/sala/:roomId/jogo/:gameId/post/:postId",
    name: "GameRelatedPosts",
    component: () =>
      import(
        /* webpackChunkName: "GameRelatedPosts" */ "../views/Games/RelatedPosts.vue"
      ),
  },
  {
    path: "/jogo/:gameId/dialeticos",
    name: "GameScreenFromDialeticsInstitution",
    props: {
      fromDialeticInstitution: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "GameScreenFromDialeticsInstitution" */ "../views/Games/Game.vue"
      ),
  },
  {
    path: "/jogo/:gameId/dialeticos/post/:postId",
    name: "GameRelatedPostsFromDialeticsInstitution",
    props: {
      fromDialeticInstitution: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "GameRelatedPostsFromDialeticsInstitution" */ "../views/Games/RelatedPosts.vue"
      ),
  },
];
