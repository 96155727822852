<template>
  <div>
    <v-app-bar elevation="1" app :color="navColor">
      <v-toolbar-title>
        <v-row class="pt-3">
          <v-col cols="3">
            φ Dialeticos
            <p class="app-bar-label">Rede social filosófica</p>
          </v-col>
        </v-row>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" :color="navColor" small v-bind="attrs" v-on="on">
            {{ userName }} <v-icon> mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn small text to="/feed"> Feed </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn small text to="/usuarios/perfil"> Perfil </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="logout()" small text> Sair </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { secondaryColor } from "@/config/config.json";
import errorHandler from "@/helpers/error_handler";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      navColor: secondaryColor,
    };
  },
  methods: {
    ...mapActions("user", ["signout"]),
    async logout() {
      try {
        this.signout();

        this.$router.push("/login");
      } catch (error) {
        console.log(error);

        const errorHandled = errorHandler.treatError(error);

        errorHandled.map((e) => this.$toast.error(e));
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    userName() {
      if (!this.userData) return null;

      return `${this.userData.firstName} ${this.userData.lastName}`;
    },
  },
};
</script>

<style scoped>
.app-bar-label {
  color: gray;
  font-size: 1.02rem;
}
</style>
