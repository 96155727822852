<template>
  <div class="institutions-and-rooms ma-4">
    <v-row>
      <v-col>
        <p class="grey--text">Salas</p>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <div v-else class="has-data">
      <div
        v-bind:key="institution.id"
        v-for="institution in institutions"
        class="mx-1 mb-2"
      >
        <v-row no-gutters class="institution-name-row pa-2">
          <v-col cols="9" class="pr-2">
            <span>{{ institution.name }}</span>
          </v-col>
          <v-col cols="3">
            <a
              title="Cadastrar sala"
              @click="openCreateRoomDialog(institution.id)"
            >
              + Sala
            </a>
          </v-col>
        </v-row>

        <v-row no-gutters class="institution-rooms">
          <v-col>
            <v-expansion-panels flat>
              <v-expansion-panel
                v-bind:key="room.id"
                v-for="room in institution.rooms"
              >
                <v-expansion-panel-header>{{
                  room.description
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn
                    x-small
                    text
                    @click="redirectToGamesOfThisRoomPage(room.id)"
                  >
                    <v-icon left>mdi-arrow-right-bold-box</v-icon>
                    Entrar</v-btn
                  >
                  <v-btn x-small text @click="openUpdateRoomDialog(room.id)">
                    <v-icon left>mdi-pencil</v-icon> Editar</v-btn
                  >
                  <v-btn x-small text @click="deleteRoom(room.id)">
                    <v-icon left>mdi-delete</v-icon>
                    Excluir</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="insertRoomDialog" max-width="800">
      <RoomsForm
        title="Criar sala"
        :modal="true"
        :institutionIdWhenModal="institutionIdWhenModal"
        mode="insert"
        @close-dialog="closeInsertRoomDialog()"
        @reload-left-bar-data="loadData()"
      />
    </v-dialog>

    <v-dialog v-model="updateRoomDialog" max-width="800">
      <RoomsForm
        title="Editar sala"
        :roomIdWhenModal="roomIdWhenModal"
        :modal="true"
        mode="update"
        @close-dialog="closeUpdateRoomDialog()"
        @reload-left-bar-data="loadData()"
      />
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import RoomsForm from "@/views/Rooms/RoomsForm.vue";

export default {
  components: {
    RoomsForm,
  },
  data() {
    return {
      loading: false,
      insertRoomDialog: false,
      updateRoomDialog: false,
      institutionIdWhenModal: null,
      roomIdWhenModal: null,
      institutions: [],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/salas`);

        this.setData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setData(data) {
      const cpData = { ...data };

      this.institutions = [...cpData.institutions];
    },
    async deleteRoom(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja remover esta sala do sistema?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/instituicoes/salas/${id}`;

        await this.$axios.delete(url);

        this.loadData();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    redirectToGamesOfThisRoomPage(id) {
      this.$router.push(`/salas/${id}`);
    },
    openCreateRoomDialog(institutionId) {
      this.institutionIdWhenModal = institutionId;
      this.insertRoomDialog = true;
    },
    openUpdateRoomDialog(roomId) {
      this.roomIdWhenModal = roomId;
      this.updateRoomDialog = true;
    },
    closeInsertRoomDialog() {
      this.institutionIdWhenModal = null;
      this.insertRoomDialog = false;
    },
    closeUpdateRoomDialog() {
      this.updateRoomDialog = false;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.institution-name-row {
  background-color: #ebe9e9;
  border-radius: 5px;
  color: black;
}

.institution-name-row a {
  color: black;
}

.institution-name-row a:hover {
  text-decoration: underline;
}

.institution-name-row:hover,
.institution-name-row:hover a {
  background-color: #252525;
  color: white;
}
</style>
