<template>
  <v-app>
    <AppBar v-if="!['Login', 'RecoveryPassword'].includes($route.name)" />

    <v-main>
      <div v-if="!['Login', 'RecoveryPassword'].includes($route.name)">
        <v-row no-gutters class="hidden-md-and-up">
          <v-col>
            <LeftBar />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="d-flex flex-row">
            <div class="hidden-sm-and-down left-bar-wrapper">
              <LeftBar />
            </div>

            <Content />
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <div class="text-center" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <LoginContent />
      </div>
    </v-main>

    <ErrorDialog ref="errorDialog"></ErrorDialog>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-app>
</template>

<script>
import AppBar from "./components/template/AppBar.vue";
import LeftBar from "./components/template/LeftBar.vue";
import Content from "./components/template/Content.vue";
import LoginContent from "./components/template/LoginContent.vue";
import ErrorDialog from "./components/template/ErrorDialog.vue";
import ConfirmDialog from "./components/template/ConfirmDialog.vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import { baseUrl } from "@/config/config.json";

export default {
  name: "App",
  components: {
    AppBar,
    LeftBar,
    Content,
    LoginContent,
    ErrorDialog,
    ConfirmDialog,
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapMutations("user", ["setUser"]),
    async checkToken() {
      try {
        if (
          window.location.href.split("/")[4].split("?")[0] == "recuperarsenha"
        )
          return;

        this.loading = true;

        const axiosInstance = axios.create({
          baseURL: baseUrl,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const response = await axiosInstance.post("/usuarios/checar-token");

        this.setUser(response.data);
      } catch (error) {
        this.$router.push("/login");
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$root.$errorDialog = this.$refs.errorDialog.open;
    this.$root.$confirm = this.$refs.confirmDialog.open;
  },
  created() {
    this.checkToken();
  },
};
</script>

<style scoped>
.v-main >>> .v-main__wrap {
  background-color: #e0e0e0 !important;
}

.left-bar-wrapper {
  min-width: 370px;
}
</style>
