const state = {
  userData: null,
  profilePhoto: null,
};

const mutations = {
  setUser(state, user) {
    state.userData = user;
  },
  clearUser(state) {
    state.userData = null;
  },
  setProfilePhoto(state, photoName) {
    state.profilePhoto = photoName;
  },
};

const actions = {
  async signin({ commit }, accessData) {
    const response = await this._vm.$axios.post("/login", accessData);

    const data = { ...response.data };

    //Salvando no store (memória)
    commit("setUser", data);
  },
  async signout({ commit }) {
    await this._vm.$axios.post("/signout");

    //Salvando no store (memória)
    commit("setUser", null);
  },
  async signup({ commit }, userData) {
    const response = await this._vm.$axios.post("/usuarios/cadastro", userData);

    const data = { ...response.data };

    //Salvando no store (memória)
    commit("setUser", data);
  },
  async loadProfilePhoto({ commit }) {
    const res = await this._vm.$axios.get(`/usuarios/foto`);

    const data = { ...res.data };

    commit("setProfilePhoto", data.profile_photo);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
