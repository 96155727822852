export default [
  {
    path: "/usuarios/perfil",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Users/Profile.vue"),
  },
  {
    path: "/admin/usuarios",
    name: "AdminUsers",
    component: () =>
      import(
        /* webpackChunkName: "AdminUsers" */ "../views/Users/UsersAdmin.vue"
      ),
  },
  {
    path: "/admin/usuarios/cadastrar",
    name: "RegisterUsersAdmin",
    props: {
      title: "Cadastrar usuários",
      mode: "insert",
      breadcrumbs: [
        {
          text: "Administração de usuários",
          href: "/#/admin/usuarios",
          disabled: false,
        },
        {
          text: "Cadastrar usuários",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterUsersAdmin" */ "../views/Users/UsersForm.vue"
      ),
  },
  {
    path: "/admin/usuarios/:id/editar",
    name: "UpdateUsersAdmin",
    props: {
      title: "Editar usuários",
      mode: "update",
      breadcrumbs: [
        {
          text: "Administração de usuários",
          href: "/#/admin/usuarios",
          disabled: false,
        },
        {
          text: "Editar usuários",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateUsersAdmin" */ "../views/Users/UsersForm.vue"
      ),
  },
];
