export default [
  {
    path: "/admin/instituicoes/:id/salas/buscar",
    name: "GetRoomsToAdmin",
    props: {
      breadcrumbs: [
        {
          text: "Administração de instituições",
          href: "/#/admin/instituicoes",
          disabled: false,
        },
        {
          text: "Administrar salas",
          href: "",
          disabled: true,
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "GetRoomsToAdmin" */ "../views/Rooms/Rooms.vue"
      ),
  },
  {
    path: "/admin/instituicoes/:institutionId/salas/cadastrar",
    name: "RegisterRooms",
    props: {
      title: "Cadastrar nova sala",
      mode: "insert",
    },
    component: () =>
      import(
        /* webpackChunkName: "RegisterRooms" */ "../views/Rooms/RoomsForm.vue"
      ),
  },
  {
    path: "/admin/instituicoes/:institutionId/salas/:roomId/editar",
    name: "UpdateRooms",
    props: {
      title: "Editar sala",
      mode: "update",
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateRooms" */ "../views/Rooms/RoomsForm.vue"
      ),
  },
  {
    path: "/salas/:id",
    name: "RoomIndexPage",
    component: () =>
      import(
        /* webpackChunkName: "RoomIndexPage" */ "../views/Rooms/RoomIndex.vue"
      ),
  },
];
