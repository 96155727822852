import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "./plugins/moment";
import axios from "./plugins/axios";

import { baseUrl } from "@/config/config.json";
import VueSocketIO from "vue-socket.io";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

Vue.use(moment);
Vue.use(axios);
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: `${baseUrl}/listenposts`,
  })
);
Vue.use(Toast);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
