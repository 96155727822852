export default [
  {
    path: "/admin/poderes",
    name: "StateEntitiesToAdmin",
    component: () =>
      import(
        /* webpackChunkName: "StateEntitiesToAdmin" */ "../views/StateEntities/StateEntities.vue"
      ),
  },
  {
    path: "/admin/poderes/:entityId/editar",
    name: "UpdateStateEntity",
    props: {
      title: "Editar poder",
      mode: "update",
    },
    component: () =>
      import(
        /* webpackChunkName: "UpdateStateEntity" */ "../views/StateEntities/StateEntitiesForm.vue"
      ),
  },
];
