<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>{{ title }} </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Nome da sala"
              placeholder="Ex: 1º ano B"
              v-model="form.description"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col>
            <UsersToManageRoomsAutocomplete
              label="Buscar Aluno"
              v-model="userForm"
              :institutionId="institutionId"
            />
          </v-col>
        </v-row>

        <div class="selected-users mt-2" v-if="selectedUsers.length">
          <v-row class="mb-2">
            <v-col>
              <h2>Selecionados</h2>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="selectedUsers"
            disable-pagination
            hide-default-footer
            :loading="loading"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="mr-2"
                title="Deletar"
                @click="deleteUser(item.id)"
                icon
                color="primary"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>

        <v-alert
          class="mt-10"
          v-if="userData.Group.group_code === 2 && mode === 'insert'"
          outlined
          color="primary"
        >
          <div>Você será incluso automaticamente nesta sala.</div>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              small
              class="primary darken-1 mr-2"
              @click="save()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> {{ saveButtonText }}
            </v-btn>
            <v-btn
              outlined
              small
              text
              @click="closeOrCancel(true)"
              :loading="loading"
            >
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeOrCancel()">Ok</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { mapState } from "vuex";
import UsersToManageRoomsAutocomplete from "@/components/Users/UsersToManageRoomsAutocomplete.vue";

export default {
  components: {
    UsersToManageRoomsAutocomplete,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    institutionIdWhenModal: {
      type: Number,
    },
    roomIdWhenModal: {
      type: Number,
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    institutionId() {
      return this.modal
        ? this.institutionIdWhenModal
        : this.$route.params.institutionId;
    },
    roomId() {
      return this.modal ? this.roomIdWhenModal : this.$route.params.roomId;
    },
    saveButtonText() {
      return this.mode === "insert" ? "Criar sala" : "Salvar alterações";
    },
  },
  data() {
    return {
      userForm: null,
      form: {},
      loading: false,
      successDialog: false,
      selectedUsers: [],
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    validateForm() {
      const errors = [];

      if (!this.form.description)
        errors.push("O campo NOME DA SALA é obrigatório");

      // if (!this.selectedUsers.length)
      //   errors.push("Selecione ao menos um usuário");

      return errors;
    },
    validateToSelectNewUser(id) {
      const index = this.selectedUsers.findIndex((p) => p.id === id);

      if (index != -1) {
        const error = ["Usuário já selecionado"];
        this.handleError(error);
        return false;
      }

      return true;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async insert() {
      try {
        this.loading = true;

        let dataToSend = this.formatDataToSend(this.form);

        await this.$axios.post(
          `admin/instituicoes/${this.institutionId}/salas/criar`,
          dataToSend
        );

        //se for modal reseta os dados
        if (this.modal) this.clearformData();

        //solicita que os dados da barra lateral sejam recarregados
        if (this.userData.Group.group_code === 2)
          this.$emit("reload-left-bar-data");

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let dataToSend = this.formatDataToSend(this.form);

        await this.$axios.put(`/admin/salas/editar`, dataToSend);

        //solicita que os dados da barra lateral sejam recarregados
        if (this.userData.Group.group_code === 2)
          this.$emit("reload-left-bar-data");

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatDataToSend(data) {
      const cpData = { ...data };

      cpData.participants = this.selectedUsers.map((u) => {
        return u.id;
      });

      //se for pra criar sala e for o professor, inclui ele automaticamente na sala
      if (this.mode === "insert" && this.userData.Group.group_code === 2)
        cpData.participants.push(this.userData.id);

      return cpData;
    },
    deleteUser(id) {
      const index = this.selectedUsers.findIndex((p) => p.id === id);

      this.selectedUsers.splice(index, 1);
    },
    closeOrCancel(cancelEdit = false) {
      if (this.mode === "insert") {
        if (this.modal) this.$emit("close-dialog");
        else this.$router.go(-1);
      } else {
        if (this.modal) this.$emit("close-dialog");
        else this.initializeForm();

        this.successDialog = false;

        if (!this.modal && cancelEdit) this.$router.go(-1);
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
    clearformData() {
      this.userForm = null;
      this.form = {};
      this.selectedUsers = [];
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/admin/salas/${this.roomId}`);

        const cpData = { ...res.data };

        const { RoomParticipants } = cpData;

        this.selectedUsers = RoomParticipants.map((u) => {
          return { fullName: `${u.firstName} ${u.lastName}`, ...u };
        });

        delete cpData.RoomParticipants;

        this.form = cpData;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    userForm(value) {
      if (!value) return;

      const valid = this.validateToSelectNewUser(value.id);

      if (valid) this.selectedUsers = [...this.selectedUsers, { ...value }];
    },
    roomIdWhenModal(value) {
      if (!value) return;

      this.initializeForm();
    },
  },
  created() {
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style scoped>
.row {
  margin: 0;
}
</style>
